import React, { useEffect } from "react";
import Layout from "../../components/layout";
import styles from "./products.module.scss";
import amplitude from "amplitude-js";
import { Button } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion";
import Product2Preview1 from "../../images/product2_preview1.jpeg";
import Product2Preview2 from "../../images/product2_preview2.jpeg";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArticleIcon from "@mui/icons-material/Article";
import { Helmet } from "react-helmet";
import { ProductPage } from "./productPage";

export const productInfo = {
  anchorTag: "2",
  title: "Forstå din baby",
  longTitle: "Forstå din baby og kom godt fra start!",
  priceDescription: "Pris: 179 kr. inklusiv moms",
  description: (
    <>
      <p>
        Græder din baby sig i søvn?
        <br />
        Er din baby urolig ved brystet eller ved flasken?
        <br />
        Er du i tvivl om hvordan du skal håndtere din babys gråd?
        <br />
        Og bliver du selv vildt ked af det eller oprevet når dit barn græder?
      </p>
      <p>
        Så er denne guide det du mangler for at få mere ro i maven og tillid til
        dig selv (og din baby)!
      </p>
    </>
  ),
  saleUrl:
    "https://barselspsykologen.simplero.com/cart/207025-Forstaa-din-baby-og-kom-godt-fra-start",
  pageUrl: "/guides/forstaa-din-baby",
  previewLabel: "Se første video gratis",
  previewUrl:
    "https://barselspsykologen.simplerosites.com/courses/100632-forstaa-din-baby-og-kom-godt-fra-start/lessons/1638416-hvorfor-forventninger-kan-vaeres",
};

const ForstaaDinBaby: React.FC = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: Forstå din baby");
  }, []);

  return (
    <Layout title={productInfo.title}>
      <Helmet>
        <meta
          name="description"
          content="Få en guide til at forstå din baby og kom godt fra start."
        />
        <meta
          name="keywords"
          content="forstå din baby, online guides, psykolog, ammespecialist, Barselspsykologen"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://barselspsykologen.dk/guides" />
        <title>Forstå din baby | Barselspsykologen</title>
      </Helmet>
      <ProductPage product={productInfo}>
        <p>
          Denne online guide giver dig 6 videoer som kort og præcist giver dig
          viden og redskaber du kan bruge med det samme, for at give dig selv
          (og dermed din baby) mere ro i maven, så I kan få den bedste start på
          livet sammen.
        </p>
        <p>
          <h3>Du får adgang til:</h3>
          <ul>
            <li>
              <VideocamIcon color="primary" fontSize="large" />
              <strong>7:40</strong>
              Hvorfor forventinger kan være skadelige.
            </li>
            <li>
              <VideocamIcon color="primary" fontSize="large" />
              <strong>10:22</strong>
              Normal Baby-søvn.
            </li>
            <li>
              <VideocamIcon color="primary" fontSize="large" />
              <strong>6:38</strong>
              Gråd ved indsovning
            </li>
            <li>
              <VideocamIcon color="primary" fontSize="large" />
              <strong>15:15</strong>
              Kom igennem baby-gråd.
            </li>
            <li>
              <VideocamIcon color="primary" fontSize="large" />
              <strong>8:59</strong>
              Normal babyadfærd omkring spisning.
            </li>
            <li>
              <VideocamIcon color="primary" fontSize="large" />
              <strong>4:54</strong>
              Tigerspring - hvad er det?
            </li>
            <li>
              <ArticleIcon color="primary" fontSize="large" />
              Vi er carry-mammals - læs dette og forstå din baby bedre
            </li>
            <li>
              <ArticleIcon color="primary" fontSize="large" />
              Tjekliste, Forebyg vuggedød (den indeholder ikke nødvendigvis det
              du tror)
            </li>
            <li>
              <ArticleIcon color="primary" fontSize="large" />
              Tjekliste, Sikker sengedeling
            </li>
            <li>
              <ArticleIcon color="primary" fontSize="large" />
              Overlevelsesstrategier til de svære dage - 10 råd fra
              barselspsykolgen til de tunge barselsdage
            </li>
          </ul>
        </p>
        <p>
          Du har øjeblikkelig adgang til alt materialet efter køb og du kan se
          det igen og igen - også med din eventuelle partner.
        </p>
        <p>
          Indholdet er tilgængeligt via{" "}
          <a
            className={styles.externalRef}
            href="https://simplero.com/"
            target="_blank"
            rel="noreferrer"
          >
            Simplero platformen
          </a>
          , og kan tilgås både via en App eller fra browseren.
        </p>
        <motion.div
          className={styles.previewWrapper}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className={styles.previewItem}>
            <img src={Product2Preview1} />
          </div>
          <div className={styles.previewItem}>
            <img src={Product2Preview2} />
          </div>
        </motion.div>
        <motion.div
          className={styles.leftButton}
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
          }}
        >
          <Button
            variant="contained"
            href={productInfo.saleUrl}
            target="_blank"
            size="large"
          >
            Få adgang nu
          </Button>
          <Button
            variant="contained"
            href={productInfo.previewUrl}
            target="_blank"
            size="large"
          >
            {productInfo.previewLabel}
          </Button>
        </motion.div>
      </ProductPage>
    </Layout>
  );
};

export default ForstaaDinBaby;
