import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import Frontpage from './pages/frontpage/frontpage';
import Amning from './pages/amning';
import Terapi from './pages/terapi';
import Kontakt from './pages/kontakt/kontakt';
import TipsForBabyCrying from './pages/video/tipsForBabyCrying';
import EnRustningAfVrede from './pages/audio/enRustningAfVrede';
import GangINedloebsrefleksen from './pages/audio/gangINedloebsrefleksen';
import NewsletterV2 from './pages/newsletter/newsletterv2';
import NewsletterConfirm from './pages/newsletter/newsletterConfirm';
import Cookies from './pages/policy/cookies';
import ModerskabIBalance from './pages/moderskabIBalance/info'
import ModerskabIBalanceBuy from './pages/moderskabIBalance/buy'
import About from "./pages/about/about";
import Handelsbetingelser from "./pages/policy/handelsbetingelser";
import Persondatapolitik from "./pages/policy/persondatapolitik";
import MindfulMorV2 from "./pages/mindfulmor/mindfulmor_v2";
import Products from './pages/onlineProducts/products';
import Unsubscribe from './pages/newsletter/unsubscribe';
import { Redirect } from 'react-router-dom';
import SurveySelvomsorg from './pages/surveys/selvomsorg';
import NotFound from './pages/NotFound';
import BlidFravaenningAfAmning from './pages/onlineProducts/blid-fravaenning-af-amning';
import ForstaaDinBaby from './pages/onlineProducts/forstaa-din-baby';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Switch>
        <Route exact path='/' component={Frontpage} />
        <Route exact path='/amning' component={Amning} />
        <Route exact path='/terapi' component={Terapi} />
        <Route exact path='/kontakt' component={Kontakt} />
        <Route exact path='/om-mig' component={About} />
        <Route exact path='/nyhedsbrev' component={NewsletterV2} />
        <Route exact path='/nyhedsbrev/bekraeft' component={NewsletterConfirm} />
        <Route exact path='/nyhedsbrev/afmeld' component={Unsubscribe} />
        <Route exact path='/policy/cookies' component={Cookies} />
        <Route exact path='/policy/handelsbetingelser' component={Handelsbetingelser} />
        <Route exact path='/policy/persondatapolitik' component={Persondatapolitik} />
        <Route exact path='/fd6v3w' component={TipsForBabyCrying} />
        <Route exact path='/3v64cp' component={EnRustningAfVrede} />
        <Route exact path='/9mi8lf' component={GangINedloebsrefleksen} />
        <Route exact path='/guides' component={Products} />
        <Route exact path='/guides/blid-fravaenning-af-amning' component={BlidFravaenningAfAmning} />
        <Route exact path='/guides/forstaa-din-baby' component={ForstaaDinBaby} />
        <Route exact path='/test/selvomsorg' component={SurveySelvomsorg} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
